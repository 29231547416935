import React, { Fragment } from 'react';
import { PseudoBox, Icon, Text, useDisclosure, useColorMode, Image } from '@chakra-ui/core';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/core';
import Slider from 'react-slick';
import desayuno1 from '../images/desayunos/1.png';
import desayuno2 from '../images/desayunos/D2.png';
import desayuno3 from '../images/desayunos/D3.png';
import desayuno4 from '../images/desayunos/D4.png';
import desayuno5 from '../images/desayunos/D5.png';
import desayuno6 from '../images/desayunos/D6.png';
import desayuno7 from '../images/desayunos/D7.png';
import desayuno8 from '../images/desayunos/D8.png';
import desayuno9 from '../images/desayunos/D9.png';
import desayuno10 from '../images/desayunos/D10.png';
import desayuno11 from '../images/desayunos/D11.png';

import comida1 from '../images/comidas/1.jpg';
import comida2 from '../images/comidas/2.jpg';
import comida3 from '../images/comidas/3.jpg';
import comida4 from '../images/comidas/4.jpg';
import comida5 from '../images/comidas/5.jpg';
import comida6 from '../images/comidas/6.jpg';
import comida7 from '../images/comidas/7.jpg';
import comida8 from '../images/comidas/8.jpg';
import comida9 from '../images/comidas/9.jpg';
import comida10 from '../images/comidas/10.jpg';
import comida11 from '../images/comidas/11.jpg';
import comida12 from '../images/comidas/12.jpg';

const Card = ({ title, icon, color }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const bg = { light: '#FBFBFD', dark: '#30231D' };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Fragment>
      <PseudoBox
        borderWidth="1px"
        textAlign="center"
        p="30px 10px"
        cursor="pointer"
        _hover={{ transform: 'translateY(0)', boxShadow: '0 8px 24px 0 rgba(31, 36, 48, 0.05)' }}
        onClick={onOpen}
      >
        <Icon name={icon} color={color} fontSize="60px" />
        <Text mt={2} fontSize="21px">
          {title}
        </Text>
      </PseudoBox>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent bg={bg[colorMode]} mt={0}>
          <ModalCloseButton zIndex="9999" bg={bg[colorMode]} />
          <ModalBody p={0}>
            {title === 'Desayunos' ? (
              <Slider {...settings}>
                <Image src={desayuno1} />
                <Image src={desayuno2} />
                <Image src={desayuno3} />
                <Image src={desayuno4} />
                <Image src={desayuno5} />
                <Image src={desayuno6} />
                <Image src={desayuno7} />
                <Image src={desayuno8} />
                <Image src={desayuno9} />
                <Image src={desayuno10} />
                <Image src={desayuno11} />
              </Slider>
            ) : (
              <Slider {...settings}>
                <Image src={comida1} />
                <Image src={comida2} />
                <Image src={comida3} />
                <Image src={comida4} />
                <Image src={comida5} />
                <Image src={comida6} />
                <Image src={comida7} />
                <Image src={comida8} />
                <Image src={comida9} />
                <Image src={comida10} />
                <Image src={comida11} />
                <Image src={comida12} />
              </Slider>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default Card;
