import React from 'react';
import { Flex, Box, useColorMode, Button, Text, Image } from '@chakra-ui/core';
import { Icon, SimpleGrid } from '@chakra-ui/core';
import logo from './images/logo.png';
import logoColor from './images/logo-color.png';
import Card from './components/Card';

const Container = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = { light: '#FBFBFD', dark: '#000' };
  const color = { light: '#3E2911', dark: '#E7E3C6' };
  const colorIcon = colorMode === 'dark' ? '#E7E3C6' : '#3E2911';
  return (
    <Flex
      minH={'100vh'}
      bg={bg[colorMode]}
      color={color[colorMode]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={5}
    >
      <Box>
        <Image src={colorMode === 'dark' ? logo : logoColor} />
        <Text textAlign="center" mt={2} fontSize={['20px', '20px', '25px']} fontWeight="400">
          Selecciona tu menú
        </Text>
        <SimpleGrid columns={[2, 1, 2, 2]} spacing="20px" mt={5}>
          <Card title="Desayunos" icon="desayunos" color={colorIcon} />
          <Card title="Comidas" icon="comidas" color={colorIcon} />
        </SimpleGrid>
      </Box>

      <Box>
        <Box display={{ base: 'none', md: 'block' }} position="fixed" right="20px" bottom="20px">
          <Button onClick={toggleColorMode}>{colorMode === 'dark' ? <Icon name="sun" /> : <Icon name="moon" />}</Button>
        </Box>
        <Box display={{ base: 'block', md: 'none' }} position="fixed" right="20px" bottom="20px" zIndex="999">
          <Button size="sm" onClick={toggleColorMode}>
            {colorMode === 'dark' ? <Icon name="sun" /> : <Icon name="moon" />}
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default Container;
